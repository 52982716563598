//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      hotelBranch: [],
    }
  },
  async mounted() {
    await this.getAllBranch()
  },

  methods: {
    async getAllBranch() {
      try {
        const response = await this.$repositories('hotel').allBranch()
        this.hotelBranch = await response.data
        this.$store.commit('landing/setAllBranch', this.hotelBranch)
      } catch (error) {
        console.log('error branch', error)
      }
    },
  },
}
