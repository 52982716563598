export default ($axios) => ({
  allBranch() {
    return new Promise((resolve, reject) => {
      $axios
        .$get(`/branches/all`)
        .then((response) => resolve(response))
        .catch((err) => reject(err))
    })
  },
})
