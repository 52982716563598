import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _85f33492 = () => interopDefault(import('../pages/policy.vue' /* webpackChunkName: "pages/policy" */))
const _25dfa932 = () => interopDefault(import('../pages/roomDetails.vue' /* webpackChunkName: "pages/roomDetails" */))
const _a8c3edfa = () => interopDefault(import('../pages/rooms.vue' /* webpackChunkName: "pages/rooms" */))
const _8bdfcc46 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _e09893d6 = () => interopDefault(import('../pages/_branch/_team/_feedslug/feedback/index.vue' /* webpackChunkName: "pages/_branch/_team/_feedslug/feedback/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/policy",
    component: _85f33492,
    name: "policy"
  }, {
    path: "/roomDetails",
    component: _25dfa932,
    name: "roomDetails"
  }, {
    path: "/rooms",
    component: _a8c3edfa,
    name: "rooms"
  }, {
    path: "/",
    component: _8bdfcc46,
    name: "index"
  }, {
    path: "/:branch/:team?/:feedslug?/feedback",
    component: _e09893d6,
    name: "branch-team-feedslug-feedback"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
