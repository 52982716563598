//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      view: {
        atTopOfPage: true,
      },
      showMenu: false,
    }
  },
  watch: {
    showMenu(val) {
      document.body.style.overflow = val ? 'hidden' : 'auto'
    },
  },

  beforeMount() {
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      if (window.pageYOffset > 60) {
        this.view.atTopOfPage = false
      } else if (window.pageYOffset === 0) {
        this.view.atTopOfPage = true
      }
    },
  },
}
