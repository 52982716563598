export default function ({ $axios, redirect }) {
  // $axios.create({
  //   headers: { Accept: 'application/json' },
  // })
  /**
   * Debug Axios on Request
   * Uncomment the following line
   */
  // $axios.onRequest((config) => {
  //   console.log(config)
  // })
  /**
   * Debug Axios on Response
   * Uncomment the following line
   */
  // $axios.onResponse((config) => {
  //   console.log(config)
  // })
  /**
   * Debug Axios on Error
   * Uncomment the following line
   */
  $axios.onError((e) => {
    console.log(e.response)
  })
  /**
   * Custom NuxtJS Axios on Error
   * Uncomment the following line
   */
  // $axios.onError((error) => {
  //   const code = parseInt(error.response && error.response.status)
  //   if (code === 401) redirect('/400')
  // })
}
