// no-use-before-define
export const state = () => ({
  planets: [],
  planet: [],
})

export const mutations = {
  SET_PLANETS(state, planets) {
    state.planets = planets
  },
  SET_PLANET(state, planet) {
    state.planet = planet
  },
}

export const actions = {
  async setPlanets({ commit }, payload) {
    try {
      const res = await this.$repositories('planet').get()
      commit('SET_PLANETS', res)
    } catch (err) {
      console.log('error', err)
    }
  },

  async setPlanet({ commit }, payload) {
    try {
      const res = await this.$repositories('planet').show(payload)
      commit('SET_PLANET', res)
    } catch (err) {
      console.log('error', err)
    }
  },
}

export const modules = {}

export const getters = {
  getPlanets: (state) => state.planets,
  getPlanet: (state) => state.planet,
}
