const basePath = 'feedback'

export default ($axios) => ({
  active(slug) {
    return new Promise((resolve, reject) => {
      $axios
        .$get(`${basePath}/active?${slug}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err))
    })
  },

  send(payload) {
    return new Promise((resolve, reject) => {
      $axios
        .$post(`${basePath}/responses`, payload)
        .then((response) => resolve(response))
        .catch((err) => reject(err))
    })
  },
})
