export const state = () => {
  return {
    hotelBranch: [],
  }
}

export const mutations = {
  setAllBranch(state, data) {
    state.hotelBranch = data
  },
}
