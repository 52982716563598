/* eslint-disable no-useless-escape */
/* eslint-disable prefer-regex-literals */
import Vue from 'vue'
// import VeeValidate from 'vee-validate'

import {
  Validator,
  install as VeeValidate,
} from 'vee-validate/dist/vee-validate.minimal.esm.js'
import {
  required,
  min,
  max,
  /* eslint-disable camelcase */
  min_value,
  max_value,
  numeric,
  regex,
  email,
  confirmed,
  decimal,
  url,
  between,
  /* eslint-disable camelcase */
  alpha_num,
} from 'vee-validate/dist/rules.esm.js'

import veeEn from 'vee-validate/dist/locale/en' // Merge the messages.
Validator.localize('en', veeEn)

// Add the rules you need.
Validator.extend('required', required)
Validator.extend('min', min)
Validator.extend('max', max)
Validator.extend('min_value', min_value)
Validator.extend('max_value', max_value)
Validator.extend('numeric', numeric)
Validator.extend('regex', regex)
Validator.extend('email', email)
Validator.extend('confirmed', confirmed)
Validator.extend('decimal', decimal)
Validator.extend('url', url)
Validator.extend('between', between)
Validator.extend('alpha_num', alpha_num)

Validator.extend('password', {
  getMessage: (field) =>
    'The ' +
    field +
    ' at least 8 chars, 1 uppercase, 1 lowercase, and 1 number',
  validate: (value) => {
    const strongRegex = new RegExp(
      '^(?=.{8,50}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])'
    )
    return strongRegex.test(value)
  },
})

Validator.extend('username', {
  getMessage: (field) =>
    'The ' +
    field +
    ' must contain at least 1 letter and can only contain lowercase alphanumeric characters (letters A-Z, numbers 0-9) and underscores between 4 - 50 characters',
  validate: (value) => {
    const strongRegex = new RegExp('^(?=.{4,50}$)(?=.*[a-z])[a-z0-9_]+$')
    return strongRegex.test(value)
  },
})

Validator.extend('phone', {
  getMessage: (field) =>
    'The ' + field + ' can only contain numeric characters (0-9)',
  validate: (value) => {
    const strongRegex = new RegExp('^[0-9/+]')
    return strongRegex.test(value)
  },
})

Validator.extend('length', {
  getMessage: (field, lengthValue) =>
    'The ' + field + ' must be ' + lengthValue + ' characters',
  validate: (value, [lengthValue]) => {
    return value.length === parseInt(lengthValue)
  },
})

Validator.extend('length_between', {
  getMessage: (field, lengthValue) =>
    'The ' +
    field +
    ' must be between ' +
    lengthValue[0] +
    ' and ' +
    lengthValue[1] +
    ' characters',
  validate: (value, lengthValue) => {
    return (
      value.length > parseInt(lengthValue[0]) &&
      value.length < parseInt(lengthValue[1])
    )
  },
})

Validator.extend('string_numeric', {
  getMessage: (field) =>
    'The ' +
    field +
    ' can only contain alphanumeric characters (letters A-Z a-z, numbers 0-9)',
  validate: (value) => {
    const strongRegex = new RegExp('^[A-Za-z0-9]+$')
    return strongRegex.test(value)
  },
})

Validator.extend('string_numeric_space', {
  getMessage: (field) =>
    'The ' +
    field +
    ' can only contain alphanumeric characters (letters A-Z a-z, numbers 0-9, or space)',
  validate: (value) => {
    const strongRegex = new RegExp('^[A-Za-z0-9- ]+$')
    return strongRegex.test(value)
  },
})

Validator.extend('string_numeric_somechar', {
  getMessage: (field) =>
    `The ${field} can only contain alphanumeric characters (letters A-Z a-z, numbers 0-9, space, or characters -.,'"()/&)`,
  validate: (value) => {
    const strongRegex = new RegExp('^[A-Za-z0-9-.,\'"()%<>=/& ]+$')
    return strongRegex.test(value)
  },
})

Validator.extend('format_hh_mm', {
  getMessage: (field) =>
    'The ' +
    field +
    ' can only contain numeric characters and format must be HH:mm',
  validate: (value) => {
    const strongRegex = new RegExp('^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$')
    return strongRegex.test(value)
  },
})

Validator.extend('uppercase_alpha_numeric', {
  getMessage: (field) =>
    'The ' +
    field +
    ' can only contain uppercase alphanumeric characters (letters A-Z or numbers 0-9).',
  validate: (value) => {
    const strongRegex = new RegExp('^[A-Z0-9]+$')
    return strongRegex.test(value)
  },
})

Validator.extend('lowercase_alpha_numeric', {
  getMessage: (field) =>
    'The ' +
    field +
    ' can only contain lowercase alphanumeric characters (letters a-z or numbers 0-9).',
  validate: (value) => {
    const strongRegex = new RegExp('^[a-z0-9]+$')
    return strongRegex.test(value)
  },
})

Validator.extend('uppercase_alpha', {
  getMessage: (field) =>
    'The ' +
    field +
    ' can only contain uppercase alpha characters (letters A-Z).',
  validate: (value) => {
    const strongRegex = new RegExp('^[A-Z]+$')
    return strongRegex.test(value)
  },
})

Validator.extend('lowercase_alpha', {
  getMessage: (field) =>
    'The ' +
    field +
    ' can only contain lowercase alpha characters (letters a-z).',
  validate: (value) => {
    const strongRegex = new RegExp('^[a-z]+$')
    return strongRegex.test(value)
  },
})

Vue.use(VeeValidate)
