import HotelRepository from '~/repositories/HotelRepository'
import FeedBackRepository from '~/repositories/FeedBackRepository'

const repositories = {
  hotel: HotelRepository,
  feedback: FeedBackRepository,
}

export const ApiRepositoryFactory = {
  get: (name) => repositories[name],
}
