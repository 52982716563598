import Vue from 'vue'
import { library, config } from '@fortawesome/fontawesome-svg-core'
import {
  FontAwesomeLayers,
  FontAwesomeLayersText,
  FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'

import {
  faHome as freeFasFaHome,
  faHeart as freeFasFaHeart,
  faTimes as freeFasFaTimes,
  faBars as freeFasFaBars,
  faCopyright as freeFasFaCopyright,
  faMapMarkerAlt as freeFasFaMapMarkerAlt,
  faPhone as freeFasFaPhone,
  faEnvelope as freeFasFaEnvelope
} from '@fortawesome/free-solid-svg-icons'

import {
  faTwitter as freeFabFaTwitter,
  faInstagram as freeFabFaInstagram,
  faFacebook as freeFabFaFacebook,
  faYoutube as freeFabFaYoutube
} from '@fortawesome/free-brands-svg-icons'

library.add(
  freeFasFaHome,
  freeFasFaHeart,
  freeFasFaTimes,
  freeFasFaBars,
  freeFasFaCopyright,
  freeFasFaMapMarkerAlt,
  freeFasFaPhone,
  freeFasFaEnvelope,
  freeFabFaTwitter,
  freeFabFaInstagram,
  freeFabFaFacebook,
  freeFabFaYoutube
)

config.autoAddCss = false

Vue.component('fa', FontAwesomeIcon)
Vue.component('faLayers', FontAwesomeLayers)
Vue.component('faLayersText', FontAwesomeLayersText)
